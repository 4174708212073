import React from 'react'
import pricing from '../../images/pricing.svg'
import {Link} from 'gatsby'
const PricingHeader = () => {
    return (
        <div>
            <header className=" bg-bgColor">
                <div className="items-center flex-col justify-center sm:grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                    <div></div>
                    <div></div>
                    <div className="text-xl sm:text-3xl mb-4 font-bold col-span-3">Pricing Plan </div>
                </div>
                <div className="sm:grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                    <div className="col-span-2 items-center justify-center lg:block hidden">
                        <div className="w-1/2">
                            <img src={pricing}/>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center text-mainColor">
                        <span className="xl:text-xl font-bold text-mainColor bg-yellowColor p-2 rounded-md px-2 mb-1">Community Edition</span>
                        <span className="font-bold text-xl">Free</span>
                        <Link to="https://mybrico.bricobrowser.website/" className="bg-mainColor text-white p-2  rounded-md no-underline m-2">Buy Now</Link>
                    </div>
                    <div className="flex flex-col items-center justify-center text-mainColor">
                        <span className="xl:text-xl font-bold text-mainColor bg-yellowColor p-2 rounded-md px-4 mb-1">Basic</span>
                        <span className="font-bold text-xl">5$/month</span>
                        <Link to="https://mybrico.bricobrowser.website/" className="text-white bg-mainColor p-2  rounded-md no-underline m-2">Buy Now</Link>
                    </div>
                    <div className="flex flex-col items-center justify-center text-mainColor">
                        <span className="xl:text-xl font-bold text-mainColor bg-yellowColor p-2 px-4 rounded-md mb-1">Pro</span>
                        <span className="font-bold text-xl">10$/month</span>
                        <Link to="https://mybrico.bricobrowser.website/" className="text-white bg-mainColor p-2  rounded-md no-underline m-2">Buy Now</Link>
                    </div>
                </div>
            </header>
        </div>
    )
}
export default PricingHeader