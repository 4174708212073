import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ColumnTitles from '../components/pricingComponents/columnTitles'
import ColumnCommunityEdition from '../components/pricingComponents/columnCommunityEdition'
import ColumnBasic from '../components/pricingComponents/columnBasic'
import ColumnPro from '../components/pricingComponents/columnPro'
import PricingHeader from '../components/pricingComponents/PricingHeader'

const Pricing = () => {
    return (
        <div>
            <Layout>
                <Seo title="Pricing" description="Pricing page BricoBrowser" /> 
                <div className="bg-bgColor">        
                    <div className="p-4 lg:px-1 xl:w-4/5 xl:m-auto">
                        <PricingHeader/>
                        <div>
                            <div className="grid md:grid-cols-3 w-full grid-cols-1 lg:grid-cols-5 text-textColor shadow-lg rounded-lg">
                                <ColumnTitles/>
                                <ColumnCommunityEdition/>
                                <ColumnBasic/>
                                <ColumnPro/>     
                            </div>
                            <p className="text-left mt-2">Note: * Screen function not available for MAC users.</p>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )

}
export default Pricing